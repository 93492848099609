import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import LinkBlock from "../../../components/link-block/"
import LogoBlock from "../../../components/logo-block/"

import { psColour, wlColour } from "../../../data/data.colours"

const OurFarmsPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			painesendBg: file(relativePath: { eq: "backgrounds/snow-covered-fields.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			churchFarmBg: file(relativePath: { eq: "backgrounds/church-farm-harvester.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			newLodgeBg: file(relativePath: { eq: "backgrounds/wheat-field-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			rectoryBg: file(relativePath: { eq: "backgrounds/sheep-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Our Farms"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={psColour.hex}
					link={`/painesend`}/>
				<Split>
					<TextBlock 
						title={`Our farms`}
						titleTag={ `h1` }
						text={`
							<p>The majority of land in the partnership is productive arable land farmed in conjunction with local contractors, and the pasture is utilized by livestock.  All our agricultural land is farmed to compliance regulation standards alongside of countryside stewardship schemes.</p>
							<p>Our farms and their surrounds provide stunning backdrops for photoshoots and commercials and are available to hire as film or television locations or bases. There are many different areas within the farms that can be used. Please contact us directly to discuss your requirements.</p>

						`}
						bgColour={`green`}/>
					<Split vertical={true}>
						<LinkBlock
							hex={psColour.hex}
							link={`/painesend/our-farms/painesend-farm`}
							text={`Painesend Farm, Tring`}
							background={ data.painesendBg.childImageSharp.fluid } />
						<LinkBlock
							hex={psColour.hex}
							link={`/painesend/our-farms/church-farm`}
							text={`Church Farm, Aldbury`}
							background={ data.churchFarmBg.childImageSharp.fluid }
							position={`50% 5%`} />
						<LinkBlock
							hex={psColour.hex}
							link={`/painesend/our-farms/new-lodge-farm`}
							text={`New Lodge Farm, Walgrave`}
							background={ data.newLodgeBg.childImageSharp.fluid } 
							position={`60% 80%`} />
						<LinkBlock
							hex={psColour.hex}
							link={`/painesend/our-farms/rectory-farm`}
							text={`Rectory Farm, Puttenham`}
							background={ data.rectoryBg.childImageSharp.fluid }
							position={`60% 90%`} />
					</Split>
				</Split>
			</Layout>
		</>
	)
}

export default OurFarmsPage